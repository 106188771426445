<template>
	<div class="dashboard">
		<div class="flex flex-row items-baseline">
			<vs-row vs-justify="left">
				<div class="d-flex align-items-center input-container pr-3 pb-3">
					<label for="period" class="input-control mr-3 mb-0">Period</label>
					<date-range-picker class="date form-control" v-model="range" :options="perfRangeOptions" :key="timezone" />
				</div>
				<div class="d-flex align-items-center input-container pr-3 pb-3">
					<label for="platform" class="input-control mr-3 mb-0">Platform</label>
					<select id="platform" class="form-control" v-model="filter_platform">
						<option value="" selected>All</option>
						<option value="facebook">Facebook</option>
						<option value="twitter">Twitter</option>
						<option value="snapchat">Snapchat</option>
						<option value="pinterest">Pinterest</option>
						<option value="tiktok">TikTok</option>
						<option value="taboola">Taboola</option>
					</select>
				</div>
				<div class="d-flex align-items-center input-container pr-3 pb-3">
					<label for="publisher" class="input-control mr-3 mb-0">Publisher</label>
					<select id="publisher" class="form-control" v-model="publisher_id">
						<option value="0" selected>All</option>
						<option v-for="p in publishers" :value="p.id" :key="p.id">
							{{ p.name }}
						</option>
					</select>
				</div>
				<div class="d-flex align-items-center input-container pr-3 pb-3">
					<label for="timezone" class="input-control mr-3 mb-0">Timezone</label>
					<select id="timezone" class="form-control" v-model="timezone">
						<option :value="timezone.value" v-for="timezone in availableTimezones" :key="timezone.value">
							{{ timezone.label }}
						</option>
					</select>
				</div>
			</vs-row>

			<vs-col class='py-3 p-md-3 mr-3' vs-w="1" vs-sm="8">
				<vs-card class="cardx">					
					<div class="text-center">
						<h5>{{ numeral(realtime).format("0,0") }}</h5>
						Realtime Users (5min)
					</div>
				</vs-card>
			</vs-col>
		</div>
		<vs-row class="margin-left-12" vs-justify="center">				
			<vs-col type="flex" class='py-3 p-md-3' vs-justify="center" vs-align="center" vs-w="2" vs-sm="12">
				<vs-card class="cardx">
					<b-spinner v-if="loading.simpleMetrics" class="spinner" label="Loading..."></b-spinner>
					<vue-apex-charts :class="{ loadingChart: loading.simpleMetrics }" ref="tinyest_revenue" height="100" width='100%' :options="tinyOptionsDollars" :series="tinySeries" />	
					<div class="text-center">
						<h5>{{ numeral(est_revenue).format("$0,0") }}</h5>
						Est. Revenue
					</div>
				</vs-card>
			</vs-col>
			<vs-col type="flex" class='py-3 p-md-3' vs-justify="center" vs-align="center" vs-w="2" vs-sm="12">
				<vs-card class="cardx">
					<b-spinner v-if="loading.simpleMetrics" class="spinner" label="Loading..."></b-spinner>
					<vue-apex-charts :class="{ loadingChart: loading.simpleMetrics }" ref="tinyspend" height="100" width='100%' :options="tinyOptionsDollars" :series="tinySeries"/>
					<div class="text-center">
						<h5>{{ numeral(spend).format("$0,0") }}</h5>
						Spend
					</div>
				</vs-card>
			</vs-col>
			<vs-col type="flex" class='py-3 p-md-3' vs-justify="center" vs-align="center" vs-w="2" vs-sm="12">
				<vs-card class="cardx">
					<b-spinner v-if="loading.simpleMetrics" class="spinner" label="Loading..."></b-spinner>
					<vue-apex-charts :class="{ loadingChart: loading.simpleMetrics }" ref="tinyest_profit" height="100" width='100%' :options="tinyOptionsDollars" :series="tinySeries" />
					<div class="text-center">
						<h5>{{ numeral(est_profit_real_spend).format("$0,0") }}</h5>
						Est. Profit
					</div>
				</vs-card>
			</vs-col>
			<vs-col type="flex" class='py-3 p-md-3' vs-justify="center" vs-align="center" vs-w="2" vs-sm="12">
				<vs-card class="cardx">
					<b-spinner v-if="loading.simpleMetrics" class="spinner" label="Loading..."></b-spinner>
					<vue-apex-charts :class="{ loadingChart: loading.simpleMetrics }" ref="tinyest_margin" height="100" width='100%' :options="tinyOptionsPercent" :series="tinySeries" />
					<div class="text-center">
						<h5>{{ numeral(est_margin).format("0.0") }}%</h5>
						Est. Margin
					</div>
				</vs-card>
			</vs-col>
			<vs-col type="flex" class='py-3 p-md-3' vs-justify="center" vs-align="center" vs-w="2" vs-sm="12">
				<vs-card class="cardx">
					<b-spinner v-if="loading.simpleMetrics" class="spinner" label="Loading..."></b-spinner>
					<vue-apex-charts :class="{ loadingChart: loading.simpleMetrics }" ref="tinyrpm" height="100" width='100%' :options="tinyOptionsDollars" :series="tinySeries" />
					<div class="text-center">
						<h5>{{ numeral(rpm).format("$0,0.0") }}</h5>
						RPM
					</div>
				</vs-card>
			</vs-col>
			<vs-col type="flex" class='py-3 p-md-3' vs-justify="center" vs-align="center" vs-w="2" vs-sm="12">				
				<vs-card class="cardx">
					<b-spinner v-if="loading.simpleMetrics" class="spinner" label="Loading..."></b-spinner>
					<vue-apex-charts :class="{ loadingChart: loading.simpleMetrics }" ref="tinylands" height="100" width='100%' :options="tinyOptions" :series="tinySeries" />
					<div class="text-center">
						<h5>{{ numeral(lands).format("0,0") }}</h5>
						Visits
					</div>
				</vs-card>															
			</vs-col>
		</vs-row>
		<vs-row class="margin-left-12" vs-justify="center">
			<vs-col type="flex" class='py-3 p-md-3' vs-justify="center" vs-align="center" vs-w="6" vs-sm="12">				
				<vs-card class="cardx">
					<b-spinner v-if="loading.simpleMetrics" class="spinner" label="Loading..."></b-spinner>
					<vue-apex-charts :class="{ loadingChart: loading.simpleMetrics }" ref="tinywins" height="100" width='100%' :options="tinyOptions" :series="tinySeries" />
					<div class="text-center">
						<h5>{{ numeral(wins).format("0,0") }}</h5>
						ADX & OB Wins
					</div>
				</vs-card>
			</vs-col>
			<vs-col type="flex" class='py-3 p-md-3' vs-justify="center" vs-align="center" vs-w="6" vs-sm="12">
				<vs-card class="cardx">
					<b-spinner v-if="loading.simpleMetrics" class="spinner" label="Loading..."></b-spinner>
					<vue-apex-charts :class="{ loadingChart: loading.simpleMetrics }" ref="tinyadx_revenue" height="100" width='100%' :options="tinyOptions" :series="tinySeries" />
					<div class="text-center">
						<h5>{{ numeral(adx_revenue).format("$0,0") }}</h5>
						ADX & OB Revenue
					</div>
				</vs-card>
			</vs-col>
		</vs-row>
		<vs-row class="margin-left-12" vs-justify="center">	
			<vs-col type="flex" class='py-3 p-md-3' vs-justify="center" vs-align="center" vs-w="4" vs-sm="12">
				<vs-card class="cardx" fixedHeight>
					<div slot="header">
						<h5>
							Revenue By Publisher
						</h5>
					</div>
					<b-spinner v-if="loading.revByPub" class="spinner" label="Loading..."></b-spinner>
					<vue-apex-charts :class="{ loadingChart: loading.revByPub }" ref="apexRevByPub" height="400" width='100%' :options="revOptions" :series="revByPub" />
				</vs-card>
			</vs-col>
			<vs-col type="flex" class='py-3 p-md-3' vs-justify="center" vs-align="center" vs-w="4" vs-sm="12">
				<vs-card class="cardx" fixedHeight>
					<div slot="header">
						<h5>
							Spend By Platform
						</h5>
					</div>
					<b-spinner v-if="loading.spendByPlat" class="spinner" label="Loading..."></b-spinner>
					<vue-apex-charts :class="{ loadingChart: loading.spendByPlat }" ref="apexSpendByPlat" height="400" width='100%' :options="spendOptions" :series="spendByPlat" />
				</vs-card>
			</vs-col>
			<vs-col type="flex" class='py-3 p-md-3' vs-justify="center" vs-align="center" vs-w="4" vs-sm="12">
				<vs-card class="cardx" fixedHeight>
					<div slot="header">
						<h5>
							Revenue By SSP
						</h5>
					</div>
					<b-spinner v-if="loading.revByBidder" class="spinner" label="Loading..."></b-spinner>
					<vue-apex-charts :class="{ loadingChart: loading.revByBidder }" ref="apexRevBySSP" height="400" width='100%' :options="revSSPOptions" :series="revBySSP" />
				</vs-card>
			</vs-col>
		</vs-row>
		<vs-row class="margin-left-12" vs-justify="center">	
			<vs-col class='py-3 p-md-3' type="flex" vs-justify="center" vs-align="center" vs-w="4" vs-sm="12">
				<vs-card class="cardx" fixedHeight>
					<div slot="header">
						<h5>
							Profit By Publisher
						</h5>
					</div>
					<b-spinner v-if="loading.profitByPub" class="spinner" label="Loading..."></b-spinner>
					<vue-apex-charts :class="{ loadingChart: loading.profitByPub }" ref="apexProfitByPub" height="400" width='100%' :options="profitOptions" :series="profitByPub" />
				</vs-card>
			</vs-col>
			<vs-col class='py-3 p-md-3' type="flex" vs-justify="center" vs-align="center" vs-w="4" vs-sm="12">
				<vs-card class="cardx" fixedHeight>
					<div slot="header">
						<h5>
							Top 5 Profitable
						</h5>
					</div>
					<b-spinner v-if="loading.topProfitCampaigns" class="spinner" label="Loading..."></b-spinner>
					<vue-apex-charts :class="{ loadingChart: loading.topProfitCampaigns }" ref="apexProfitCampaigns" height="400" width='100%' :options="topProfitOptions" :series="topProfitCampaigns" />
				</vs-card>
			</vs-col>
			<vs-col class='py-3 p-md-3' type="flex" vs-justify="center" vs-align="center" vs-w="4" vs-sm="12">
				<vs-card class="cardx" fixedHeight>
					<div slot="header">
						<h5>
							Top 5 Margins
						</h5>
					</div>
					<b-spinner v-if="loading.topMarginCampaigns" class="spinner" label="Loading..."></b-spinner>
					<vue-apex-charts :class="{ loadingChart: loading.topMarginCampaigns }" ref="apexMarginCampaigns" height="400" width='100%' :options="topMarginOptions" :series="topMarginCampaigns" />
				</vs-card>
			</vs-col>
		</vs-row>
	</div>
</template>

<script>
	import numeral from 'numeral'
	import moment from 'moment-timezone';
	import VueApexCharts from 'vue-apexcharts'	
	import { PUBLISHERS } from "../utils/product_entities.js";
	import { AVAILABLE_TIMEZONES, datePickerRanges, tinyOptions } from "../utils/values.js";

	export default{				
		data() {
			return {
				loading: {
					revByPub: true,
					revByBidder: true,
					profitByPub: true,
					topProfitCampaigns: true,
					topMarginCampaigns: true,
					spendByPlat: true,
					simpleMetrics: true
				},
				refreshInterval: null,
				sub: null,
				realtime: 0,
				numeral: numeral,
				est_revenue: 0,
				spend: 0,
				est_profit_real_spend: 0,
				est_profit: 0,
				margin: 0,
				est_margin: 0,
				rpm: 0,
				lands: 0,
				wins: 0,				
				adx_revenue: 0,
				publisher_id: 0,
				publishers: PUBLISHERS,
				availableTimezones: AVAILABLE_TIMEZONES,
				timezone: localStorage.getItem("tz") || 'UTC',
				filter_platform: '',
				selectedDate: {
					start: moment().startOf('day'),
					end: moment().endOf('day')
				},
				datePickerRanges: datePickerRanges,
				range: [moment().startOf('day'), moment().endOf('day')],
				perfRangeOptions: {
					alwaysShowCalendars: true,
					maxSpan: { "days": 365 },
					maxDate: moment().add(1,'days'),
					autoApply: true,
					ranges: datePickerRanges()
				},
				tinySeries: [{
					name: '',					
					data: []
				}],
				revByPub: [{					
					name: '',					
					data: []
				}],
				profitByPub: [{					
					name: '',					
					data: []
				}],
				revBySSP: [{					
					name: '',					
					data: []
				}],
				spendByPlat: [{					
					name: '',					
					data: []
				}],
				topProfitCampaigns: [{					
					name: '',					
					data: []
				}],
				topMarginCampaigns: [{					
					name: '',					
					data: []
				}],				
				revOptions: {
					legend: {
						position: 'top'
					},					
					plotOptions: {
						pie: {
							donut: {
								labels: {
									show: true,
									name: { show: true },
									value: { 
										show: true,
										formatter: function (val) {
											return numeral(val).format("$0,0");
										}
									},
									total: { 										
										show: true,
										label: 'Total Revenue',
										formatter: function (w) {
											return numeral(w.globals.seriesTotals.reduce((a, b) => {
												return a + b
											}, 0)).format("$0,0")
										}
									}
								}
							}
						}
					},
					labels: PUBLISHERS.map(p => { return p.name }),
					colors: PUBLISHERS.map(p => { return p.color }),
					grid: {
						show: true
					},          					
					chart: {						
						type: 'donut',
						toolbar: {
							show: false,
						}            
					},
					dataLabels: {
						enabled: true
					},															
					yaxis: [{
						labels: {
							formatter: function (value) {
								return numeral(value).format("$0,0");
							}
						}
					}],
					xaxis: {
						labels: {
							formatter: function (value) {
								return value;
							}
						}
					}
				},
				revSSPOptions: {
					legend: {
						position: 'top'
					},
					plotOptions: {
						pie: {
							donut: {
								labels: {
									show: true,
									name: { show: true },
									value: { 
										show: true,
										formatter: function (val) {
											return numeral(val).format("$0,0");
										}
									},
									total: { 										
										show: true,
										label: 'Total Revenue',
										formatter: function (w) {
											return numeral(w.globals.seriesTotals.reduce((a, b) => {
												return a + b
											}, 0)).format("$0,0")
										}
									}
								}
							}
						}
					},
					labels: PUBLISHERS.map(p => { return p.name }),
					grid: {
						show: true
					},          					
					chart: {      						
						type: 'donut',                
						toolbar: {
							show: false,
						}            
					},
					dataLabels: {
						enabled: true
					},															
					yaxis: [{

					}]					
				},
				spendOptions: {
					plotOptions: {
						bar: {
							distributed: true
						}
					},
					legend: {
						show: false
					},										
					grid: {
						show: true
					},					
					chart: {      						
						type: 'bar',                
						toolbar: {
							show: false,
						}            
					},													
					dataLabels: {
						enabled: false,
						formatter: function (value) {
							return numeral(value).format("$0,0");
						}
					},
					xaxis: {						
						labels: {
							formatter: function (value) {
								return value;
							}
						}
					},															
					yaxis: [{
						labels: {
							formatter: function (value) {
								return numeral(value).format("$0,0");
							}
						}
					}]				
				},
				profitOptions: {
					legend: {
						position: 'top'
					},			
					plotOptions: {
						bar: {
							distributed: true
						}
					},							
					grid: {
						show: true
					},          					
					chart: {      						
						type: 'bar',
						toolbar: {
							show: false,
						}            
					},
					dataLabels: {
						enabled: false,
						formatter: function (value) {
							return numeral(value).format("$0,0");
						}
					},
					colors: PUBLISHERS.map(p => { return p.color }),
					xaxis: {
						categories: PUBLISHERS.map(p => { return p.name }),
						labels: {
							formatter: function (value) {
								return value;
							}
						}
					},															
					yaxis: [{
						labels: {
							formatter: function (value) {
								return numeral(value).format("$0,0");
							}
						}
					}]
				},
				topProfitOptions: {
					legend: {
						position: 'bottom',
						horizontalAlign: 'left',						
					},
					grid: {
						show: true
					},          					
					chart: {      						
						type: 'line',                
						toolbar: {
							show: false,
						}            
					},					
					dataLabels: {
						enabled: false,
						formatter: function (value) {
							return numeral(value).format("$0,0");
						}
					},
					xaxis: {	
						type: "datetime"
					},															
					yaxis: [{
						labels: {
							formatter: function (value) {
								return numeral(value).format("$0,0");
							}
						}
					}],
					tooltip: {
						x: {
							format: 'dd MMM yyyy HH:mm'
						}
					}			
				},
				topMarginOptions: {
					legend: {
						position: 'bottom',						
						horizontalAlign: 'left'				
					},										
					grid: {
						show: true
					},
					chart: {      						
						type: 'line',                
						toolbar: {
							show: false,
						}            
					},
					dataLabels: {
						enabled: false						
					},
					xaxis: {	
						type: "datetime"
					},
					yaxis: [{
						labels: {
							formatter: function (value) {
								return numeral(value).format("0") + '%';
							}
						}
					}],
					tooltip: {
						x: {
							format: 'dd MMM yyyy HH:mm'
						}
					}			
				},
				tinyOptions: tinyOptions(value => numeral(value).format("0,0")),
				tinyOptionsDollars: tinyOptions(value => numeral(value).format("$0,0.0")),
				tinyOptionsPercent: tinyOptions(value => `${numeral(value).format("0.0")}%`)
			}
		},
		beforeCreate() {
			moment.tz.setDefault(localStorage.getItem("tz") || this.timezone || 'UTC');
		},
		mounted()
		{
			this.sub = this.$cable.subscriptions.create({
				channel: 'AnalyticsChannel',
			}, {
				received: this.handlePayloadReceived,
				connected: function() {
					this.perform("hello", {})
				}
			});

			this.startRefreshScheduler();
			this.loadAll();
		},
		beforeDestroy() {
			this.clearRefreshScheduler();
			this.sub.unsubscribe();
		},
		watch: {
			timezone(n) {
				localStorage.setItem("tz", n);
				moment.tz.setDefault(n);

				let start = moment(this.range[0], "DD/MM/YYYY").tz(n, true).startOf("day");
				let end = moment(this.range[1], "DD/MM/YYYY").tz(n, true).endOf("day");

				this.protectRange(start, end);

				this.$set(this.perfRangeOptions, "ranges", this.datePickerRanges());
				this.$set(this.perfRangeOptions, "startDate", start);
				this.$set(this.perfRangeOptions, "endDate", end);
				this.$set(this, "selectedDate", { start, end });

				this.loadAll();
			},
			filter_platform(_n, _o)
			{
				this.loadAll();
			},
			publisher_id(_n, _o)
			{
				this.loadAll();
			},
			range(n) {

				const start = moment(n[0], "DD/MM/YYYY");
				const end = moment(n[1], "DD/MM/YYYY");
				
				// TODO: Remove this once we have rollup per TZ
				this.protectRange(start, end);

				this.$set(this, "selectedDate", {
					start: start.startOf("day"),
					end: end.endOf("day")
				});

				if (!start.isSame(new Date(), "day"))
					this.clearRefreshScheduler();
				else
					this.startRefreshScheduler();

				this.loadAll();
			}
		},
		methods: {
			protectRange(start, end)
			{
				// TODO: Remove this once we have rollup per TZ
				const diff = end.diff(start, 'days', false); 
				
				if (diff > 13 && this.timezone != 'UTC')
				{
					alert('Cant work on these ranges on TZ other than UTC');
					window.location.reload();
				}
			},
			startRefreshScheduler()
			{
				this.refreshInterval = setInterval(_ => this.loadAll(true), 30 * 1000);
			},
			clearRefreshScheduler()
			{
				if (this.refreshInterval){
					clearInterval(this.refreshInterval)
					this.refreshInterval = null;
				}
			},
			handlePayloadReceived(payload) {				
				if (!payload)
					return;

				this.realtime = Object.values(payload.realtime).reduce((acc, val) => acc + val, 0)
			},
			async loadAll(skipSpinners = false) {
				if (!skipSpinners) {
					this.loading.revByPub = true;
					this.loading.revByBidder = true;
					this.loading.profitByPub = true;
					this.loading.topProfitCampaigns = true;
					this.loading.topMarginCampaigns = true;
					this.loading.spendByPlat = true;
					this.loading.simpleMetrics = true;
				}

				const res = await this.$http.get(`${this.host}/statistics.json`, {
					params: {
						start: this.selectedDate.start,
						end: this.selectedDate.end,
						platform: this.filter_platform ? [this.filter_platform] : [],
						publisher_id: this.publisher_id,
					},
				});

				const payload = res.data;
				this.loadRevByPub(payload.rev_by_pub).finally(() => this.loading.revByPub = false)
				this.loadRevByBidder(payload.rev_by_bidder).finally(() => this.loading.revByBidder = false)
				this.loadProfitByPub(payload.profit_by_pub).finally(() => this.loading.profitByPub = false)
				this.loadTopProfitCampaigns(payload.top_profit_campaigns).finally(() => this.loading.topProfitCampaigns = false)
				this.loadTopMarginCampaigns(payload.top_profit_campaigns).finally(() => this.loading.topMarginCampaigns = false)
				this.loadSpendByPlat(payload.spend_by_platform).finally(() => this.loading.spendByPlat = false)
				this.loadAllMetrics(payload.all_metrics).finally(() => this.loading.simpleMetrics = false)
			},
			async loadAllMetrics(payload) {
				const timeOffsetInMinutes = this.selectedDate.start._offset;
				const milisecondsInMinute = 60000;
				const milisecondsTimezoneOffset =
					timeOffsetInMinutes * milisecondsInMinute;

				[
					"est_revenue",
					"spend",
					"est_profit_real_spend",
					"est_profit",
					"est_margin",
					"rpm",
					"lands",
					"wins",
					"adx_revenue",
				].forEach((key) => {
					this[key] = payload.total[key];

					let metrics = payload.metrics.map((d) => {
						return [
							d["key"] + milisecondsTimezoneOffset,
							d[key] ? d[key]["value"] : 0,
						];
					});

					let a = [{ name: key, type: "area", data: metrics }];
					let ref = this.$refs["tiny" + key];
					if (ref) {
						ref.updateSeries(a);
						ref.chart.clearAnnotations();
						let now = new Date();
						now = now.setUTCMinutes(0, 0, 0) + milisecondsTimezoneOffset;

						ref.chart.addXaxisAnnotation({
							id: "now-annotation",
							x: now,
							borderColor: "#00E396",
							label: {
								orientation: "horizontal",
								style: {
									color: "#00E396",
								},
								text: "Now",
							},
						});
					}
				});
			},
			async loadSpendByPlat(payload) {				
				let data = Object.keys(payload).map((k) => {
					return parseInt(payload[k].toFixed(1));
				});

				let spend = [{ name: "Spend", data: data }];

				let colors = Object.keys(payload).map((k) => {
					switch (k) {
						case "snapchat":
							return "#fffc00";
						case "twitter":
							return "#00aced";
						case "facebook":
							return "#3b5998";
						case "pinterest":
							return "#cb2027";
						case "taboola":
							return "blue";
						default:
							return "#000000";
					}
				});

				this.spendOptions = {
					colors: colors,
					labels: Object.keys(payload).map((k) => {
						return k;
					}),
				};

				this.$refs["apexSpendByPlat"].updateSeries(spend);
			},
			async loadRevByPub(payload) {				
				let rev = PUBLISHERS.map((p) => {
					return parseInt((payload[p.id.toString()] || 0).toFixed(1));
				});

				this.$refs["apexRevByPub"].updateSeries(rev);
			},
			async loadProfitByPub(payload) {				
				let rev = [
					{
						name: "Profit",
						data: PUBLISHERS.map((p) => {
							return parseInt((payload[p.id.toString()] || 0).toFixed(1));
						}),
					},
				];

				this.$refs["apexProfitByPub"].updateSeries(rev);
			},
			async loadTopProfitCampaigns(payload) {				
				let profit = [];

				Object.keys(payload).forEach((c) => {
					profit.push({
						name: payload[c].metadata.display_name,
						type: "line",
						data: payload[c].performance.map((d) => {
							return [d.key, d.profit.value];
						}),
					});
				});

				this.$refs["apexProfitCampaigns"].updateSeries(profit);
			},
			async loadTopMarginCampaigns(payload) {				
				let margin = [];

				Object.keys(payload).forEach((c) => {
					margin.push({
						name: payload[c].metadata.display_name,
						type: "line",
						data: payload[c].performance.map((d) => {
							return [d.key, d.margin.value];
						}),
					});
				});

				this.$refs["apexMarginCampaigns"].updateSeries(margin);
			},
			async loadRevByBidder(payload) {				
				let rev = Object.keys(payload).map((k) => {
					return parseInt(payload[k].toFixed(1));
				});

				this.revSSPOptions = {
					labels: Object.keys(payload).map((k) => {
						return k;
					}),
				};

				this.$refs["apexRevBySSP"].updateSeries(rev);
			},
		},
		components: {
			VueApexCharts
		}
	}
</script>
<style lang="scss">
.spinner {
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.loadingChart {
	opacity: 0.5;
}
</style>