<template>
  <div v-if="campaignDoneLoading">
    <CampaignForm :name                     = "name"
                  :duplicateMode            = "true"
                  :submit                   = "submit"
                  :setName                  = "setName"
                  :creator                  = "creator"
                  :uniq_num                 = "uniq_num"
                  :campaign                 = "campaign"
                  :inProgress               = "inProgress"
                  :disabledFieldsByPlatform = "isFieldDisabled"
    />
    <div v-if="submitError" class="alert alert-danger">{{ submitError }}</div>
  </div>
</template>
<script>
import CampaignForm from "../components/campaign-form/CampaignForm.vue";
import { DISABLED_FIELDS, SERIALIZE, NAME_GENERATORS } from "../utils/campaign_form.js";
import { CAMPAIGN_TEMPLATE } from "../utils/dup_campaign.js";
import { LOGGER, ilog, elog, alog} from "../utils/logger/logger.js";

export default {
  name: "DuplicateCampaign",
  data: function() {
    return {
      name: '',
      uniq_num: '',
      submitError: null,
      inProgress: false,
      creator: null,
      campaignDoneLoading: false,
      campaign: {}
    }
  },
  mounted() {
    LOGGER.initSession('DuplicateCampaign', this.$http, this.host, this.$route.params.id);

    this.creator = this.$user;
    this.uniq_num = NAME_GENERATORS.uniqNum()
    this.loadCampaign()
    .then(() => this.campaignDoneLoading = true)
  },
  beforeDestroy() {
    LOGGER.terminateSession();
  },
  methods: {
    isFieldDisabled(field, platform, _id = null){
      return DISABLED_FIELDS.assert(field, platform)
    },
    checkName: function(model, element) {
      if (model === '') return;

      let regExp = element.includes('adName') ? /^[a-z0-9\-_]+$/i : /^[a-z0-9\-_ ]+$/i;
      if (model.match(regExp) === null)
        document.getElementById(element).setCustomValidity("You have non alpha numeric values");
      else
        document.getElementById(element).setCustomValidity("");
    },
    setName(displayName){
      if (this.campaignDoneLoading) this.name = displayName.toLowerCase().replace(/ +/g, '_');
    },
    async loadCampaign() {
      let template = await CAMPAIGN_TEMPLATE.make(this.$http, this.host, this.$route.params.id);
      delete template.name;
      this.campaign = template;
      this.loadAudiences();
    },
    loadAudiences() {
      if (!this.$route.query.audiences) return;

      this.campaign.adsetAudiences = [];
      let audiences = decodeURIComponent((this.$route.query.audiences)).split(',');
      while(audiences.length > 0) {
        let item = audiences.splice(0,2);
        this.campaign.adsetAudiences.push({uid: item[0], name: item[1]});
      }
    },
    submit(formCamp) {
      this.submitError = null;
      this.inProgress = true;

      formCamp = SERIALIZE.dispatching(formCamp)
      formCamp.campaigns[0].dup_type = 'single'

      alog('Submit', 'payload', formCamp);
      this.$http
      .post(`${this.host}/campaigns`, formCamp, { timeout: 120000 })
      .then((res) => {
        ilog('campaign created successfully')
        this.$router.push({
          name: "campaigns",
          query: { filter_campaign_id: res.data[0] }
        });
      })
      .catch(ex => {
        if (ex.response) {
          this.submitError = ex.response.data.error;
        } else {
          this.submitError = ex.message || ex.error || ex;
        }
        this.submitError = `${LOGGER.getSessionId()}: ${this.submitError}`;
      }).finally(() => {
        this.inProgress = false;
      });
    }
  },
  components: {
    CampaignForm
  }
};
</script>
<style>
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
  font-size: 2rem;
}

.hr-text:before {
  content: "";
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;

  padding: 0 0.5em;
  line-height: 1.5em;
  color: #818078;
  background-color: #ffffff;
}

.dz {
  margin-bottom: 1rem;
}

.creative-size {
  font-weight: bold;
}

.multiselect__tag {
  background: #e02866 !important;
}

.multiselect__option--highlight {
  background: #e02866 !important;
}

.multiselect__option--highlight:after {
  background: #e02866 !important;
}

.alert-danger {
  white-space: pre-line;
}

.submit-hr {
  margin: 3rem;
}

button.update-btn {
  line-height: 0.8rem;
  padding: 0.3rem 0.2rem;
  font-size: inherit;
  margin-right: 1rem;
}

div div.creatives-update-container {
  display: flex;
  justify-content: center;
}

span.gurl {
  word-break: break-word;
}

.move-left {
  width: auto;
  box-shadow: none;
}

.bold-red {
  font-weight: 700;
  color: red;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
