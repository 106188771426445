<template>
<div v-if="campaignDoneLoading">
  <div class="creatives-update-container">
    <b-button :variant="submitError ? 'danger' : 'warning'" size="sm" v-if="aFieldHasChanged"
      class="rounded-pill update-btn update-creatives-btn" @click="sendUpdate"
      :disabled="isUpdating || filesQueueLength > 0">{{ submitButtonText }}
    </b-button>
  </div>
  <CampaignForm :editMode="true" :resetOriginalValues="resetOriginalValues" :disabledFieldsByPlatform="isFieldDisabled" :inProgress="inProgress" :setName="setName" :name="name" :creator="creator" :uniq_num="uniq_num" :campaign="campaign"></CampaignForm>
  <div v-if="submitError" class="alert alert-danger">{{ submitError }}</div>
</div>
</template>

<script>
import CampaignForm from "../components/campaign-form/CampaignForm.vue";
import { UTILS, LOAD_CAMPAIGN, DISABLED_FIELDS, SERIALIZE } from "../utils/campaign_form.js";
import { getChanges } from "../utils/edit_campaign.js";
import { LOGGER, ilog, elog, alog } from "../utils/logger/logger.js"

export default {
  name: "EditCampaign",
  data: function() {
    return {
      name: '',
      uniq_num: '',
      submitError: null,
      inProgress: false,
      creator: null,
      campaignDoneLoading: false,
      campaign: {},
      syncedCampaign: null,
      isUpdating: false,
      aFieldHasChanged: false,
      succecfullPayloads: []
    }
  },
  mounted() {
    LOGGER.initSession('EditCampaign', this.$http, this.host, this.$route.params.id);

    this.loadCampaign()
    .then(() => this.campaignDoneLoading = true)
    this.$eventHub.$on("fieldChanged", () => {
      this.aFieldHasChanged = true;})
  },
  beforeDestroy() {
    LOGGER.terminateSession();
  },
  computed: {
    filesQueueLength() {
      const form = this.$children.find(c => c.$options.name === "CampaignForm")
      if (!form) return 0;

      return form.filesQueue.length;
    },
    submitButtonText() {
      if (this.filesQueueLength > 0) return 'Uploading files...';
      if (this.isUpdating)  return 'Saving...';
      return 'Save Changes';
    }
  },
  methods: {
    isFieldDisabled(field, platform, id = null){
      let fields = ['utm_campaign', 'submit', 'platform', 'publisher', 'adAccount', 'objective', 'custom_conversion', 'campaign_budget']
      let platformSpecific = { snapchat: ['goal'] }

      if (id) fields = [...fields, 'max_bid', 'adset_budget']
      if (id && platformSpecific[platform]) fields = [...fields, ...platformSpecific[platform]]

      return DISABLED_FIELDS.assert(field, platform) || fields.includes(field)
    },
    setName(_displayName) {
      return;
    },
    resetOriginalValues() {
      this.syncedCampaign = this.getCurrentCampaign();
      this.aFieldHasChanged = false;
    },
    getCurrentCampaign() {
      let camp = this.$children.find(c => c.$options.name == "CampaignForm")
      if (camp) return SERIALIZE.dispatching(UTILS.clone(camp.toObj()))
    },
    async loadCampaign() {
      this.campaign = await LOAD_CAMPAIGN.get(this.$http, this.host, this.$route.params.id)
      this.name = this.campaign.name
      this.creator = this.campaign.assignee
      this.uniq_num = this.campaign.uniq_num

      this.formatLoadedAdsets()
    },
    formatLoadedAdsets() {
      this.campaign.adsets = this.campaign.adsets.map(adset => {
        adset.creatives = this.formatLoadedCreatives(adset.creatives)
        return {
          serialNum: isNaN(parseInt(adset.name)) ? 1 : parseInt(adset.name),
          loadedData: adset
        }
      })
    },
    formatLoadedCreatives(creatives){
      if (!creatives) return [];

      creatives.forEach(cre => {
        cre.serialNum = parseInt(cre.name.match(/\d+$/)[0])
      })

      return creatives
    },
    validate() {
      return this.$children.find(c => c.$options.name == "CampaignForm").validateForm()
    },
    async sendUpdate() {
      this.submitError  = null;
      this.isUpdating   = true;

      if (!this.validate()) {
        this.isUpdating = false;
        return;
      }

      let payloads = getChanges(this.syncedCampaign, this.getCurrentCampaign(), this.succecfullPayloads);
      const origCounter = payloads.length;

      if (origCounter === 0) {
        alert('no changes made!');
        this.isUpdating = false;
        this.aFieldHasChanged = false;
        return;
      }

      let i = 0;
      while(payloads.length > 0) {
        let payload = payloads.shift();
        alog('save updates', `payload ${++i}/${origCounter}`, payload);
        await this.$http.put(`${this.host}/campaigns/${this.$route.params.id}/update`, payload)
          .then(() => {
            this.succecfullPayloads.push(payload);
            ilog(`payload ${i}/${origCounter} updated successfully`);
          })
          .catch(ex => {
            if (ex.response) {
              this.submitError = ex.response.data.error;
            } else {
              this.submitError = ex.message || ex.error || ex;
            }

            elog(`Editing Error (payload ${i}/${origCounter})`, this.submitError);
            this.submitError = `${LOGGER.getSessionId()}: ${this.submitError}`;
            this.isUpdating = false;
            this.aFieldHasChanged = false;
            payloads = [];
          })
      }
      ilog(`No more payloads. reloading data...`);
      this.$router.go();
    }
  },
  components: {
    CampaignForm
  }
};
</script>
<style>
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
  font-size: 2rem;
}

.hr-text:before {
  content: "";
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;

  padding: 0 0.5em;
  line-height: 1.5em;
  color: #818078;
  background-color: #ffffff;
}

.dz {
  margin-bottom: 1rem;
}

.creative-size {
  font-weight: bold;
}

.multiselect__tag {
  background: #e02866 !important;
}

.multiselect__option--highlight {
  background: #e02866 !important;
}

.multiselect__option--highlight:after {
  background: #e02866 !important;
}

.alert-danger {
  white-space: pre-line;
}

.submit-hr {
  margin: 3rem;
}

button.update-btn {
  line-height: 0.8rem;
  padding: 0.3rem 0.2rem;
  font-size: inherit;
  margin-right: 1rem;
}

div div.creatives-update-container {
  display: flex;
  justify-content: center;
}

button.update-btn.update-creatives-btn {
  position: fixed;
  right: 15rem;
  bottom: 2rem;
  transform: translateX(10rem);
  line-height: 1.8rem;
  font-size: medium;
  padding: 0.3rem 0.5rem;
  z-index: 999;
}

span.gurl {
  word-break: break-word;
}

.move-left {
  width: auto;
  box-shadow: none;
}

.bold-red {
  font-weight: 700;
  color: red;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
