<template>
  <b-form inline autocomplete="off" @submit.prevent="submit" class="text-center">
    <b-input-group prepend="Title" class="form-width">
      <label :for="title" hidden>Title</label>
      <b-form-input v-model="title"></b-form-input>
    </b-input-group>
    <hr/>
    <b-card :key="dup.bindingKey" v-for="dup in dups">
      <template v-slot:header>
        <p v-if="dup.name.length >= 300" class="bold-red mb-0">({{dup.name.length}}/300)</p>
        <h6 class="mb-0">{{ dup.display_name }}</h6>
        <b-alert v-if="dup.status == 200" show variant="success"><b>Uploaded Successfully</b></b-alert>
        <b-alert v-if="dup.submitError" show variant="danger"><b>{{ dup.submitError }} </b><b-button size="sm" pill variant='outline-dark' :disabled="inProgress" @click="submit(dup)">{{dup.inProgress ? 'Creating...' : 'Retry'}}</b-button></b-alert>
        <b-button size="sm" class="delete-btn" @click="deleteDup(dup)">X</b-button>
      </template>
      <template>
      <b-container>
        <b-form-row>
        <b-input-group prepend="Ad Account">
          <label for="adAccounts" hidden>Ad Account</label>
          <b-form-select v-model="dup.adAccount" :id="`ad_account-${dup.name}`">
            <option :key="acc.name" :value="acc.id" v-for="acc in computedAdAccounts">{{ acc.name }}</option>
          </b-form-select>
        </b-input-group>

        <b-input-group v-if="(platform_options.daily_budget || {}).campaign" prepend="Campaign Daily Budget" append="$">
          <label for="daily_budget" hidden>daily_budget</label>
          <b-form-input class="mini-sloth" v-model="dup.daily_budget" :id="`Daily Budget-${dup.name}`"></b-form-input>
        </b-input-group>

        <b-input-group prepend="Custom Name">
          <label :for="dup.custom_name" hidden>Custom Name</label>
          <b-form-input v-model="dup.custom_name"></b-form-input>
        </b-input-group>
        </b-form-row>

        <ABTestPicker :ref="'ab-tests-' + dup.bindingKey" :campaign="originalCampaign" :abTests="dup" :platform="platform"/>

        <b-form-row>
          <AdsetsMassDup :platformOptions="platform_options" :gurl="gurl" :isCustomConversion="isCustomConversion" :conversionList="conversionLists[dup.adAccount]" :dupKey="dup.bindingKey" :dup="dup"></AdsetsMassDup>
        </b-form-row>
      </b-container>
      </template>
    </b-card>
    <b-button @click="addNewDup" :disabled="inProgress">+</b-button>
    <hr class="submit-hr" />
    <div v-if="submitError" class="alert alert-danger">{{ submitError }}</div>
    <input type="submit" :disabled="inProgress" :value="inProgress ? 'Creating...' : 'Create Campaigns'" class="btn btn-success" />
  </b-form>
</template>

<script>
import ABTestPicker from "../components/campaign-form/ABTestPicker.vue"
import AdsetsMassDup from "../components/mass-dup/AdsetsMassDup.vue"
import { CAMPAIGN_TEMPLATE } from "../utils/dup_campaign.js";
import { OPTIONS, VALIDATION, UTILS, CUSTOM_CONVERSION, COMPUTED_PROPS, NAME_GENERATORS, SERIALIZE } from "../utils/campaign_form.js";
import { LOGGER, ilog, elog, alog } from "../utils/logger/logger.js";

export default {
  name: "massDuplicate",
  data: function() {
    return {
      creator: {},
      originalCampaign: {},
      dups: [],
      submitError: null,
      inProgress: false,
      adAccounts: [],
      platform_options: {},
      isCustomConversion: false,
      conversionLists: {},
      loaded_conversion_rule: null,
      publisher: null,
      platform: null,
      objective: null,
      utm_source: null,
      title: 'Loading...',
      url: null,
      customParams: { isPurchase: false }
    }
  },
  mounted() {
    LOGGER.initSession('MassDuplicate', this.$http, this.host, this.$route.params.id);
    this.creator = this.$user
    this.loadAdAccounts()
    .then(() => this.loadCampaign())
    .then(() => this.addNewDup())
  },
  beforeDestroy() {
    LOGGER.terminateSession();
  },
  computed: {
    computedAdAccounts: function() {
      return this.adAccounts.filter(ad => {
        return ad.platform === this.platform && ad.publisher_id === this.publisher;
      });
    }
  },
  watch: {
    title: function() {
      this.dups.forEach(dup => { this.renameDup(dup) })
    },
    dups: {
      deep: true,
      handler() {
        this.dups.forEach(dup => { this.renameDup(dup) })
      }
    }
  },
  methods: {
    async loadAdAccounts() {
      let res = await OPTIONS.provided(this.$http, this.host)
      this.adAccounts = res.ad_accounts;
      this.conversionLists = res.fb_conversion_lists;
    },
    async loadCampaign() {
      this.originalCampaign = await CAMPAIGN_TEMPLATE.make(this.$http, this.host, this.$route.params.id)
      const clone = UTILS.clone(this.originalCampaign)

      this.platform = clone.platform
      this.platform_options = OPTIONS.platform(this.platform) || {};
      this.publisher = clone.publisher
      this.isCustomConversion = CUSTOM_CONVERSION.isSupported(clone.objective, clone.platform, clone.adAccount)
      this.customParams.isPurchase = CUSTOM_CONVERSION.isPurchase(clone.adsets[0])

      this.title = clone.title
      this.orig_camp_name = clone.name
      delete this.originalCampaign.title
      delete this.originalCampaign.name
    },
    gurl(dupName, adName) {
      const { url, utm_medium, utm_source } = this.originalCampaign
      return COMPUTED_PROPS.gurl(url, utm_medium, utm_source, dupName, adName, this.platform)
    },
    renameDup(dup) {
      let renamed_context = Object.assign({}, this, dup)
      dup.display_name = NAME_GENERATORS.campaign(renamed_context)
      dup.name = dup.display_name.toLowerCase().replace(/ +/g, '_')
    },
    addNewDup() {
      let newDup = UTILS.clone(this.originalCampaign)
      newDup.bindingKey = UTILS.bindingKey();
      newDup.uniq_num = NAME_GENERATORS.uniqNum()
      newDup.inProgress  = false;
      newDup.submitError = null;
      newDup.status      = null;

      alog('add new dup', this.dups.length + 1, newDup);

      this.dups.push(newDup)
    },
    deleteDup(deletedDup){
      alog('delete dup', 'dup', deletedDup);
      this.dups = this.dups.filter(dup => dup !== deletedDup)
    },
    toObj(dup) {
      const adsets = this.$children.find(child => child.$options.name == 'AdsetsMassDup' && child.dupKey == dup.bindingKey).toObj()
      const ab_tests = this.$refs[`ab-tests-${dup.bindingKey}`][0].toObj();
      const clone = UTILS.clone(this.originalCampaign)

      let formCamp = {
        platform: this.platform,
        dry: false,
        ad_account_id: dup.adAccount,
        campaigns: [{
          name: dup.name,
          dup_type: 'mass',
          uniq_num: dup.uniq_num,
          orig_camp_name: this.orig_camp_name,
          display_name: dup.display_name,
          title: this.title,
          custom_name: dup.custom_name,
          utm_medium: clone.utm_medium,
          units: clone.units,
          objective: clone.objective,
          daily_budget: dup.daily_budget,
          metadata: dup.metadata,
          ab_tests,
          adsets: adsets
        }]
      }

      return formCamp
    },
    submit(_dup) {
      ilog('submiting campaign duplicates')
      const dups = _dup.name ? [_dup] : this.dups;
      this.submitError  = null;
      this.inProgress   = true;
      let requestsDone  = 0;

      dups.forEach(dup => {
        dup.inProgress  = true;
        dup.submitError = null;
        dup.status      = null;

        let payload = this.toObj(dup)
        let camp = payload.campaigns[0]

        if (!VALIDATION.abTesting(camp.ab_tests)) {
          dup.submitError = "Change monetization presets and retry";
          dup.inProgress  = false;
          requestsDone++;
          if (requestsDone === dups.length) this.inProgress = false;
          return
        }

        if (!VALIDATION.utm_campaign(camp.name)) {
          dup.submitError = "Change name and retry";
          dup.inProgress  = false;
          requestsDone++;
          if (requestsDone === dups.length) this.inProgress = false;
          return
        }

        if (!VALIDATION.bid(camp.adsets)) {
          dup.submitError = "Lower Bid and retry";
          dup.inProgress  = false;
          requestsDone++;
          if (requestsDone === dups.length) this.inProgress = false;
          return
        }

        if (!VALIDATION.creatives(camp.adsets)) {
          dup.submitError = "One or more adsets have no creatives";
          dup.inProgress  = false;
          requestsDone++;
          if (requestsDone === dups.length) this.inProgress = false;
          return
        }

        payload = SERIALIZE.dispatching(payload);
        alog('submiting dup', 'dup', payload)
        this.$http.post(`${this.host}/campaigns`, payload, { timeout: 120000 })
          .then(res => {
            dup.inProgress = false;
            dup.status = res.status;
            requestsDone++;
            ilog(`dup created succesfully (${requestsDone}/${dups.length})`)
            if (requestsDone === dups.length) this.inProgress = false;
           })
          .catch(ex => {
            dup.inProgress = false;
            requestsDone++
            if (requestsDone === dups.length) this.inProgress = false;
            if (ex.response) {
              let error = ex.response.data.error;
              dup.submitError = error.substr(0, error.indexOf('\n'))
            } else {
              dup.submitError = ex.message || ex.error || ex;
            }
            elog(`error in dup creation (${requestsDone}/${dups.length})`, dup.submitError, dup)
            dup.submitError = `${LOGGER.getSessionId()}: ${dup.submitError}`;
          })
      })
    }
  },
  components: {
    ABTestPicker,
    AdsetsMassDup
  }
};
</script>
<style>
.multiselect__tag {
  background: #e02866 !important;
}

.multiselect__option--highlight {
  background: #e02866 !important;
}

.multiselect__option--highlight:after {
  background: #e02866 !important;
}
</style>
<style scoped>
.submit-hr {
  margin: 3rem;
}

.form-control {
padding-top: 0;
padding-bottom: 0;
align-items: center;
height: inherit;
}

form.form-inline {
  flex-direction: column;
}

div.card {
 margin: 0.5rem;
}

div.card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card div.card-body {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

div.card-body .input-group {
  padding: 0.5rem;
}

div.input-group.form-width {
  width: 70%;
}

button.delete-btn {
  color: red;
  font-weight: 600;
  font-size: 1rem;
  background: none;
  border: none;
}

.bold-red {
  font-weight: 700;
  color: red;
}
input.mini-sloth{
 width: 5rem;
}

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
