<template>
  <div v-if="loading">
    <b-spinner></b-spinner>
  </div>
  <b-form v-else inline autocomplete="off" @submit.prevent="submit" class="text-center">
    <b-input-group prepend="Platform" class="form-width">
      <label :for="count" hidden>Platform</label>
      <b-form-input disabled :value="platform"></b-form-input>
    </b-input-group>
    <br />
    <b-input-group prepend="Count" class="form-width">
      <label :for="count" hidden>Count</label>
      <b-form-input max="25" v-model.number="count" type="number"></b-form-input>
    </b-input-group>
    <hr />
    <b-card>
      <template v-slot:header>
        <p v-if="dup.display_name.length >= 300" class="bold-red mb-0">
          ({{ dup.display_name.length }}/300)
        </p>
        <h6 class="mb-0">{{ dup.display_name }}</h6>
      </template>
      <template>
        <b-container>
          <b-form-row>
            <b-input-group prepend="Ad Account">
              <div class="self-center ml-2">
                <span>All ad accounts in publisher except</span>
                <span>
                  {{
                    this.adAccounts.find(
                      (adAccount) => adAccount.id === dup.adAccount
                    ).name
                  }}
                </span>
              </div>
            </b-input-group>

            <b-input-group v-if="(platform_options.daily_budget || {}).campaign" prepend="Campaign Daily Budget"
              append="$">
              <b-form-input class="mini-sloth" v-model="dup.daily_budget" :id="`Daily Budget-${dup.name}`"></b-form-input>
            </b-input-group>

            <b-input-group prepend="Custom Name">
              <b-form-input :disabled="true" v-model="dup.custom_name"></b-form-input>
            </b-input-group>

            <b-input-group prepend="Schedule">
              <b-form-select v-model="start_time" :options="start_time_options">
              </b-form-select>
            </b-input-group>
          </b-form-row>

          <b-card class="m-1" id="ab_presets">
            <template v-slot:header>
              <h6 v-b-toggle.ab_presets variant="primary" class="mb-0 w-full text-left">
                Monetization presets
              </h6>
            </template>
            <b-collapse id="ab_presets" class="mt-2">
              <ABTestPicker :ref="'ab-tests-' + dup.bindingKey" :campaign="originalCampaign" :abTests="dup"
                :platform="platform" :editMode="false" :disableInput="true" @input="value => ab_tests = value"
                @toObject="value => abTestsToObject = value" />
            </b-collapse>
          </b-card>
          <br />
          <b-form-row>
            <b-form-checkbox switch v-model="usePostFromDup" class="ml-2">Use post from duplicated</b-form-checkbox>
          </b-form-row>
          <b-form-row>
            <AdsetsMassDup :platformOptions="platform_options" :gurl="gurl" :isCustomConversion="isCustomConversion"
              :conversionList="conversionLists[dup.adAccount]" :dup="dup" :disableAllFields="true" v-model="adsets"
              @input="value => adsets = value" @toObject="value => adsetsToObject = value"></AdsetsMassDup>
          </b-form-row>
        </b-container>
      </template>
    </b-card>
    <br />
    <feather-icon v-if="isComplete" icon="CheckCircleIcon" style="color: #5cb85c" />
    <b-spinner v-else-if="inProgress" />
    <input v-else type="submit" :value="'Generate Campaigns'" class="btn btn-success" :disabled="inProgress" />
    <br />
    <div v-if="submitError" style="color: #c53030" class="d-flex flex-row">
      <feather-icon icon="AlertCircleIcon" />
      &nbsp;
      <span class="text-center">{{ submitError }}</span>
    </div>
  </b-form>
</template>

<script>
import ABTestPicker from "../components/campaign-form/ABTestPicker.vue";
import AdsetsMassDup from "../components/mass-dup/AdsetsMassDup.vue";
import { CAMPAIGN_TEMPLATE } from "../utils/dup_campaign.js";
import {
  OPTIONS,
  VALIDATION,
  UTILS,
  CUSTOM_CONVERSION,
  COMPUTED_PROPS,
  NAME_GENERATORS,
  SERIALIZE,
} from "../utils/campaign_form.js";
import { cloneDeep } from '../utils/edit_campaign';
import FeatherIcon from '../components/FeatherIcon.vue';
import { isProduction } from '../utils/env';

export default {
  name: "massGenerate",
  components: {
    ABTestPicker,
    AdsetsMassDup,
    FeatherIcon
  },

  data: function () {
    return {
      loading: true,
      creator: {},
      originalCampaign: {},
      dup: {},
      submitError: null,
      inProgress: false,
      isComplete: false,
      countries: [],
      adAccounts: [],
      platform_options: {},
      isCustomConversion: false,
      conversionLists: {},
      publisher: null,
      platform: null,
      objective: null,
      utm_source: null,
      count: 1,
      url: null,
      customParams: { isPurchase: false },
      ab_tests: null,
      adsets: null,
      adsetsToObject: null,
      abTestsToObject: null,
      start_time_options: [
        { text: '', value: '' },
        { text: 'Start tomorrow', value: '0' },
        { text: 'Start in 2 days', value: '24' },
        { text: 'Start in 3 days', value: '48' }
      ],
      start_time: '0',
      usePostFromDup: false
    };
  },

  mounted() {
    this.creator = this.$user;
    this.loadAdAccounts()
      .then(() => this.loadCampaign())
      .then(() => {
        this.initializeDup();
        this.loading = false;
      });
  },

  computed: {
    computedAdAccounts: function () {
      return this.adAccounts.filter((ad) => ad.platform === this.platform && ad.publisher_id === this.publisher);
    },
  },

  methods: {
    async loadAdAccounts() {
      let res = await OPTIONS.provided(this.$http, this.host);
      this.adAccounts = res.ad_accounts;
      this.conversionLists = res.fb_conversion_lists;
    },
    async loadCampaign() {
      this.originalCampaign = await CAMPAIGN_TEMPLATE.make(
        this.$http,
        this.host,
        this.$route.params.id
      );
      const clone = UTILS.clone(this.originalCampaign);

      this.platform = clone.platform;
      this.platform_options = OPTIONS.platform(this.platform) || {};
      this.publisher = clone.publisher;
      this.isCustomConversion = CUSTOM_CONVERSION.isSupported(
        clone.objective,
        clone.platform,
        clone.adAccount
      );
      this.customParams.isPurchase = CUSTOM_CONVERSION.isPurchase(
        clone.adsets[0]
      );

      this.title = clone.title;
      this.orig_camp_name = clone.name;
      delete this.originalCampaign.title;
      delete this.originalCampaign.name;
    },
    gurl(dupName, adName) {
      const { url, utm_medium, utm_source } = this.originalCampaign;
      return COMPUTED_PROPS.gurl(
        url,
        utm_medium,
        utm_source,
        dupName,
        adName,
        this.platform
      );
    },
    initializeDup() {
      const dup = UTILS.clone(this.originalCampaign);
      dup.uniq_num = NAME_GENERATORS.uniqNum();
      this.dup = renameDup(this, dup);
    },
    toObj(dup) {
      const clone = UTILS.clone(this.originalCampaign);
      const adsetsClone = UTILS.clone(this.adsetsToObject());

      return {
        platform: this.platform,
        dry: !isProduction,
        ad_account_id: dup.adAccount,
        campaigns: [
          {
            name: dup.name,
            dup_type: "generate",
            uniq_num: dup.uniq_num,
            orig_camp_name: this.orig_camp_name,
            display_name: dup.display_name,
            custom_name: dup.custom_name,
            utm_medium: clone.utm_medium,
            units: clone.units,
            objective: clone.objective,
            daily_budget: dup.daily_budget,
            metadata: dup.metadata,
            ab_tests: this.ab_tests,
            adsets: adsetsClone,
            start_time: this.start_time,
            title: dup.title
          },
        ],
      };
    },

    generateCampaigns() {
      const cvHop = 0.02;
      const cvMin = 0.01;
      const cvMax = 0.25;

      const possibleAdAccounts = this.computedAdAccounts.filter(
        (adAccount) => adAccount.id !== this.dup.adAccount.id && !adAccount.code_name.includes("CTC")
      );
      const adAccountsIds = possibleAdAccounts.map((adAccount) => adAccount.id);
      const adAccountCvs = this.conversionLists[this.dup.adAccount];
      const randomAdAccounts = createRandomsFromArray(adAccountsIds, this.count);

      const { custom_conversion } = this.adsetsToObject()[0];

      const getSiteCvPrefix = this.publisher === 2 ? '' : Object.keys(adAccountCvs)[0].split(' ')[0] + ' ';
      const currentCv = Number.parseFloat(custom_conversion.rule.match(/0\.\d\d/g)[0]);
      const cvs = createHopsArray(cvMin, cvMax, cvHop, this.count, currentCv).map((cv) => {
        const key = `${getSiteCvPrefix}Val=${cv.toFixed(2)}`;
        return adAccountCvs[key];
      });

      const zipped = randomAdAccounts.map((adAcc, index) => ([adAcc, cvs[index]]));
      return zipped.map(([adAccountId, custom_conversion]) => this.generateCampaign({ adAccountId, custom_conversion }));
    },

    generateCampaign({ adAccountId, custom_conversion }) {
      const clonedDup = renameDup(this, { ...cloneDeep(this.dup), adAccount: adAccountId, uniq_num: NAME_GENERATORS.uniqNum() });
      const duplicated = this.toObj(clonedDup);
      const duplicatedCampaign = cloneDeep(duplicated.campaigns[0]);

      Object.assign(this.dup, { name: clonedDup.name });
      Object.assign(this.adsets[0], { custom_conversion });
      for (const abTest of this.ab_tests) {
        abTest.percentage = 100 / this.ab_tests.length;
      }

      const adsetsObject = this.adsetsToObject();
      const abTestsObject = this.abTestsToObject();

      Object.assign(adsetsObject[0], {
        creatives: adsetsObject[0].creatives.map(creative => ({ ...creative, orig_ad_id: this.usePostFromDup ? creative.id : null })),
        start_time: this.start_time
      });
      if (this.usePostFromDup) for (const creative of adsetsObject[0].creatives) delete creative.id;

      const campaign = { ...duplicatedCampaign, adsets: adsetsObject, ab_tests: abTestsObject };

      return {
        ...duplicated,
        ad_account_id: adAccountId,
        campaigns: [campaign],
      };
    },

    submit() {
      this.submitForm().catch((ex) => {
        this.inProgress = false;
        this.isComplete = false;
        if (ex.response) {
          let error = ex.response.data.error;
          this.submitError = error.substr(0, error.indexOf("\n"));
        } else {
          this.submitError = ex.message || ex.error || ex;
        }
      });
    },

    async submitForm() {
      this.inProgress = true;
      this.isComplete = false;
      this.submitError = null;

      const payloads = this.generateCampaigns();

      const serializedPayloads = payloads.map(payload => {
        const camp = payload.campaigns[0];

        if (!VALIDATION.abTesting(camp.ab_tests)) {
          this.submitError = "Change monetization presets and retry";
        }

        if (!VALIDATION.utm_campaign(camp.name)) {
          this.submitError = "Change name and retry";
        }

        if (!VALIDATION.bid(camp.adsets)) {
          this.submitError = "Lower Bid and retry";
        }

        if (!VALIDATION.creatives(camp.adsets)) {
          this.submitError = "One or more adsets have no creatives";
        }

        if (this.submitError) {
          this.inProgress = false;
          return;
        }

        const serialized = SERIALIZE.dispatching(payload);
        return serialized;
      });

      const sleep = ms => new Promise(r => setTimeout(r, ms));
      await this.$http
        .post(`${this.host}/campaigns/create_multiple`, { campaigns: serializedPayloads }, { timeout: 120000 })
        .then(() => {
          this.inProgress = false;
          this.isComplete = true;
        }).then(() => sleep(1000)).then(() => {
          const { title } = this.dup;
          this.$router.push(`/campaigns?showNew=true&displayName="${title}"`);
        });
    },
  },
};

function createHopsArray(min, max, hop, size, start = min) {
  if (start < min || start > max) throw new Error('start must be in range');

  const diff = max - min;
  return new Array(size).fill().map((_, index) => min + (hop * (index + 1) + start - min) % diff);
}

function createRandomsFromArray(array, size) {
  const newArray = [];
  let arrayClone = Array.from(array);

  for (let i = 0; i < size; i++) {
    const randomIndex = getRandomNumber(0, arrayClone.length - 1);
    newArray.push(arrayClone[randomIndex]);
    arrayClone.splice(randomIndex, 1);
    if (arrayClone.length === 0) arrayClone = Array.from(array);
  }

  return newArray;
}

function getRandomNumber(min, max, type = 'integer') {
  const num = Math.random() * (max - min) + min;
  const integer = type === 'integer';
  return integer ? Math.floor(num) : num;
}

function renameDup(massGenerateComponentInstance, dup) {
  let renamed_context = Object.assign({}, massGenerateComponentInstance, dup);
  dup.display_name = NAME_GENERATORS.campaign(renamed_context);
  dup.name = dup.display_name.toLowerCase().replace(/ +/g, "_");
  dup.title = renamed_context.title;
  return dup;
}
</script>

<style>
.multiselect__tag {
  background: #e02866 !important;
}

.multiselect__option--highlight {
  background: #e02866 !important;
}

.multiselect__option--highlight:after {
  background: #e02866 !important;
}
</style>
<style scoped>
.submit-hr {
  margin: 3rem;
}

.form-control {
  padding-top: 0;
  padding-bottom: 0;
  align-items: center;
  height: inherit;
}

form.form-inline {
  flex-direction: column;
}

div.card {
  margin: 0.5rem;
}

div.card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card div.card-body {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

div.card-body .input-group {
  padding: 0.5rem;
}

div.input-group.form-width {
  width: 70%;
}

button.delete-btn {
  color: red;
  font-weight: 600;
  font-size: 1rem;
  background: none;
  border: none;
}

.bold-red {
  font-weight: 700;
  color: red;
}

input.mini-sloth {
  width: 5rem;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
